import axios from 'axios';
import { saveAs } from 'file-saver';

export const getTwelveBankAccountsApi = async () => {
  const response = await axios.get('/admin/deal/bank-account');
  return response.data;
};

export const getCustomersDealsOverviewApi = async ({ revenueRecognition }) => {
  const response = await axios.get('/admin/deal/customer', {
    params: {
      revenueRecognition,
    },
  });
  return response.data;
};

export const getCustomerInstallmentsOverviewApi = async ({
  customerId,
  revenueRecognition,
}) => {
  const response = await axios.get(
    `/admin/deal/customer/${customerId}/installments`,
    {
      params: {
        revenueRecognition,
      },
    }
  );
  return response.data;
};

export const getInstallmentApi = async ({ customerId, installmentId }) => {
  const response = await axios.get(
    `/admin/deal/customer/${customerId}/installment/${installmentId}`
  );
  return response.data;
};

export const createInstallmentApi = async ({ customerId, data }) => {
  const response = await axios.post(
    `/admin/deal/customer/${customerId}/installment`,
    data
  );
  return response.data;
};

export const updateInstallmentApi = async ({
  customerId,
  installmentId,
  data,
}) => {
  const response = await axios.put(
    `/admin/deal/customer/${customerId}/installment/${installmentId}`,
    data
  );
  return response.data;
};

export const getRemainingInstallmentsApi = async ({ customerId }) => {
  const response = await axios.get(
    `/admin/deal/customer/${customerId}/remaining-installments`
  );
  return response.data;
};

export const createRepaymentAllocationApi = async ({
  customerId,
  bankAccountId,
  transferDate,
  allocations,
}) => {
  const response = await axios.post(
    `/admin/deal/customer/${customerId}/repayment-allocation`,
    {
      bankAccountId,
      transferDate,
      allocations,
    }
  );
  return response.data;
};

export const getInstallmentRepaymentsApi = async ({
  customerId,
  installmentId,
  revenueRecognition,
}) => {
  const response = await axios.get(
    `/admin/deal/customer/${customerId}/installment/${installmentId}/repayments`,
    {
      params: {
        revenueRecognition,
      },
    }
  );
  return response.data;
};

export const updateCascadeAssetTapeReportApi = async () => {
  const response = await axios.get(
    '/admin/deal/update-cascade-asset-tape-report'
  );
  return response.data;
};

export const getDealsApi = async ({ customerId }) => {
  const response = await axios.get(`/admin/deal/customer/${customerId}/deals`);
  return response.data;
};

export const createDealApi = async ({ customerId, data }) => {
  const response = await axios.post(
    `/admin/deal/customer/${customerId}/deal`,
    data
  );
  return response.data;
};

export const getDealApi = async ({ customerId, dealId }) => {
  const response = await axios.get(
    `/admin/deal/customer/${customerId}/deal/${dealId}`
  );
  return response.data;
};

export const getAmortizationApi = async ({ customerId, refreshData }) => {
  const response = await axios.get(
    `/admin/deal/customer/${customerId}/amortization`,
    {
      params: {
        refreshData,
      },
    }
  );
  return response.data;
};

export const calcInstallmentAmortizationApi = async ({
  customerId,
  dealType,
  purchasePrice,
  fee,
  repaymentFrequency,
  repaymentDays,
  daysBeforeEndOfMonth,
  numberOfRepayments,
  firstRepaymentDate,
  cohortStartDate,
  cohortEndDate,
  pctFromRevenueShare,
  revenueTotalType,
  isTrueUp,
}) => {
  const response = await axios.post(
    `/admin/deal/customer/${customerId}/calc-installment-amortization`,
    {
      dealType,
      purchasePrice,
      fee,
      repaymentFrequency,
      repaymentDays,
      daysBeforeEndOfMonth,
      numberOfRepayments,
      firstRepaymentDate,
      cohortStartDate,
      cohortEndDate,
      pctFromRevenueShare,
      revenueTotalType,
      isTrueUp,
    }
  );
  return response.data;
};

export const downloadInstallmentsReportApi = async ({ fileName }) => {
  const response = await axios.get(`admin/deal/installment-report`, {
    responseType: 'blob',
  });

  const blob = new Blob([response.data], { type: response.data.type });
  saveAs(blob, fileName);
};

export const downloadCustomerRepaymentsReportApi = async ({
  customerId,
  fileName,
  downloadDate,
}) => {
  const response = await axios.get(
    `admin/deal/customer/${customerId}/repayments-report`,
    {
      responseType: 'blob',
      params: {
        downloadDate,
      },
    }
  );

  const blob = new Blob([response.data], { type: response.data.type });
  saveAs(blob, fileName);
};
